import { computed, type ComputedRef } from 'vue';
import { OfferDto, PaginationStep } from '@containex/portal-backend-dto';
import { useOfferStore } from '../stores/offers';
import { offersApi } from '@containex/portal-backend-api-client';
import { httpClient } from '@/common/api/http-client';

export interface OfferQuery {
    offers: ComputedRef<OfferDto[]>;
    totalOffersAmount: ComputedRef<number>;
}

export interface OfferAction {
    fetchOffers(offersAmount: number, step: PaginationStep): Promise<void>;
}

export function useOfferQuery(): OfferQuery {
    const store = useOfferStore();

    return {
        offers: computed(() => store.offers),
        totalOffersAmount: computed(() => store.totalOffersAmount),
    };
}

export function useOfferAction(): OfferAction {
    const store = useOfferStore();

    return {
        async fetchOffers(offersAmount: number, step: PaginationStep): Promise<void> {
            const offers = store.offers;
            let pageCursor;

            if (step === PaginationStep.New) {
                pageCursor = null;
            } else {
                const filterOrder = step === PaginationStep.Next ? offers[offers.length - 1] : offers[0];
                pageCursor = {
                    startDate: filterOrder?.date.toLocaleString(),
                    startId: filterOrder?.id,
                };
            }

            const response = await offersApi.fetchOffers(httpClient, {
                offersAmount: String(offersAmount),
                step,
                pageCursorDate: pageCursor?.startDate,
                pageCursorId: pageCursor?.startId,
            });

            store.offers = response.data.offers;
            store.totalOffersAmount = response.data.totalOffersAmount;
        },
    };
}
