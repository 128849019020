import { defineStore } from 'pinia';
import { OfferDto } from '@containex/portal-backend-dto';

interface OfferState {
    offers: OfferDto[];
    totalOffersAmount: number;
}

export const useOfferStore = defineStore('offer', {
    state: (): OfferState => ({
        offers: [],
        totalOffersAmount: 0,
    }),
});
