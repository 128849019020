<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import DataTable, { type DataTablePageEvent } from 'primevue/datatable';
    import Column from 'primevue/column';
    import Button from 'primevue/button';
    import { useAsyncTask } from 'vue-concurrency';
    import { useI18n } from 'vue-i18n';
    import { useDateFormatter } from '@/composables/date-format';
    import { parseISO } from 'date-fns';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { useOfferAction, useOfferQuery } from '../composables/offer';
    import { offersApi } from '@containex/portal-backend-api-client';
    import { immediatelyDownloadFile } from '@/util/download';
    import useGlobalToast from '@/composables/useGlobalToast';
    import { isStringEmpty } from '@containex/common-utils';
    import { getLogger } from '@/logger/logger';
    import { httpClient } from '@/common/api/http-client';
    import { type FetchPaginatedDataDto, PaginationStep, ProvisionType } from '@containex/portal-backend-dto';
    import { ref } from 'vue';
    import { DEFAULT_ROW_AMOUNT_OPTIONS_TABLE, DEFAULT_ROWS_PER_TABLE } from '@/constants';

    const { t } = useI18n();
    const { offers, totalOffersAmount } = useOfferQuery();
    const offerAction = useOfferAction();
    const { dateFormatter } = useDateFormatter();
    const { formatPrice } = usePriceFormatter();
    const { errorToastOptions, addToast } = useGlobalToast();
    const logger = getLogger('OfferHistoryView');

    const isLoading = ref(false);
    const paginatorCurrentRows = ref(DEFAULT_ROWS_PER_TABLE);
    const paginatorCurrentPage = ref(0);
    const paginatorPageLinkSize = ref(2);

    const task = useAsyncTask(async () => {
        await offerAction.fetchOffers(DEFAULT_ROWS_PER_TABLE, PaginationStep.New);
    }).perform();

    const fetchOffers = useAsyncTask(async (signal, data: FetchPaginatedDataDto) => {
        logger.debug('OfferHistoryView', 'fetching orders for table');
        isLoading.value = true;
        await offerAction.fetchOffers(data.dataAmount, data.step);
        isLoading.value = false;
    }).drop();

    function filteredReferences(reference: string, referenceA: string, referenceB: string): string[] {
        return [reference, referenceA, referenceB].filter((r) => !isStringEmpty(r));
    }

    async function downloadDocument(offerId: string): Promise<void> {
        try {
            const response = await offersApi.getDocument(httpClient, offerId);

            if (response.data.url != null) {
                const url = response.data.url;
                await immediatelyDownloadFile(url, offerId);
            } else if (response.data.url == null) {
                throw Error('URL must not be null');
            }
        } catch (error) {
            logger.error(error, 'Error happened while downloading document');
            addToast({
                ...errorToastOptions,
                summary: 'ERROR.DOWNLOAD_FILE.SUMMARY',
                detail: 'ERROR.DOWNLOAD_FILE.DETAIL',
            });
        }
    }

    async function handlePaginatorChange(event: DataTablePageEvent): Promise<void> {
        if (paginatorCurrentPage.value === event.page && paginatorCurrentRows.value === event.rows) {
            return;
        }

        if (paginatorCurrentRows.value !== event.rows) {
            paginatorCurrentRows.value = event.rows;
            await fetchOffers.perform({
                dataAmount: event.rows,
                step: PaginationStep.New,
            });
            event.page = 0;
        } else {
            await fetchOffers.perform({
                dataAmount: event.rows,
                step: paginatorCurrentPage.value < event.page ? PaginationStep.Next : PaginationStep.Previous,
            });
        }

        paginatorCurrentPage.value = event.page;
        getPageLinkSize();
    }

    function getPageLinkSize(): void {
        const lastPage = Math.ceil(totalOffersAmount.value / paginatorCurrentRows.value) - 1;
        if (paginatorCurrentPage.value === 0 || paginatorCurrentPage.value === lastPage) {
            paginatorPageLinkSize.value = 2;
        } else {
            paginatorPageLinkSize.value = 3;
        }
    }
</script>

<template>
    <div class="order-history-page-container">
        <h2 class="text-2xl-bold-line-height-auto">{{ t('ACCOUNT.OFFERS.TITLE') }}</h2>
        <div v-if="task.isSuccessful">
            <BlockUI :blocked="fetchOffers.isRunning">
                <DataTable
                    v-if="fetchOffers.isRunning || offers.length > 0"
                    :value="offers"
                    paginator
                    :total-records="totalOffersAmount"
                    :lazy="true"
                    :rows="paginatorCurrentRows"
                    :rows-per-page-options="DEFAULT_ROW_AMOUNT_OPTIONS_TABLE"
                    :loading="isLoading"
                    :page-link-size="paginatorPageLinkSize"
                    :first="paginatorCurrentPage * paginatorCurrentRows"
                    :current-page-report-template="
                        t('ACCOUNT.PAGINATOR', {
                            first: '{first}',
                            last: '{last}',
                            totalRecords: '{totalRecords}',
                        })
                    "
                    paginator-template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    @page="handlePaginatorChange"
                >
                    <Column field="id" :header="t('ACCOUNT.OFFERS.ID')" />
                    <Column field="date" :header="t('ACCOUNT.OFFERS.DATE')">
                        <template #body="slotProps">
                            {{ dateFormatter.formatDate(parseISO(slotProps.data.date)) }}
                        </template>
                    </Column>
                    <Column field="type" :header="t('ACCOUNT.OFFERS.TYPE')">
                        <template #body="slotProps">
                            {{
                                slotProps.data.type === ProvisionType.Sales
                                    ? t('ACCOUNT.OFFERS.SALES')
                                    : t('ACCOUNT.OFFERS.RENTAL')
                            }}
                        </template>
                    </Column>
                    <Column field="reference" :header="t('ACCOUNT.OFFERS.REFERENCE')">
                        <template #body="slotProps">
                            <ul class="reference-list">
                                <li
                                    v-for="reference of filteredReferences(
                                        slotProps.data.reference,
                                        slotProps.data.reference_a,
                                        slotProps.data.reference_b
                                    )"
                                    :key="reference"
                                >
                                    {{ reference }}
                                </li>
                            </ul>
                        </template>
                    </Column>
                    <Column field="amount" :header="t('ACCOUNT.OFFERS.AMOUNT')">
                        <template #body="slotProps">
                            <div class="table-column-price">
                                {{ formatPrice(slotProps.data.amount) }}
                            </div>
                        </template>
                    </Column>
                    <Column field="document">
                        <template #body="slotProps">
                            <div class="center">
                                <Button
                                    :disabled="slotProps.data.has_document !== true"
                                    icon="pi pi-download"
                                    outlined
                                    @click="downloadDocument(slotProps.data.id)"
                                />
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <p v-else>
                    {{ t('ACCOUNT.OFFERS.NO_OFFERS') }}
                </p>
            </BlockUI>
        </div>

        <LoadingSpinner v-else-if="task.isRunning" />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .order-history-page-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: main.$spacing-6;
    }

    .reference-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .table-column-price {
        text-align: right;
    }
</style>
